import {inject, Injectable} from '@angular/core';
import {DynamicClassService} from './dynamic-class.service';

export type DefaultStyle = { tablet?: {}, mobile?: {}, desktop?: {} };
export type DefaultCols = { 'col-'?: number, 'col-sm-'?: number, 'col-md-'?: number, 'col-lg-'?: number, 'col-xl-'?: number };
/** @deprecated */
export type DeprecatedCols = { 'xsmall'?: number, 'small'?: number, 'medium'?: number, 'large'?: number, 'xlarge'?: number };

@Injectable({
  providedIn: 'root'
})
export class StoryblokStylingService {
  private dynamicCssClass = inject(DynamicClassService);



  getClassList(generalStyling: any, defaults?: DefaultStyle, defaultColumns?: DefaultCols, deprecatedCols?: DeprecatedCols): string[] {
    const classList: any[] = [];

    if (!generalStyling && !defaults && !defaultColumns) {
      return classList;
    }

    const classes = this.dynamicCssClass.generateCssClass(generalStyling, defaults);
    if (classes) {
      classList.push(classes);
    }

    const classname = generalStyling?.classname;
    if (classname) {
      classname.split(',').forEach((c: string) => {
        classList.push(c.trim());
      });
    }

    const cols: any = this.getCols(generalStyling?.columns, defaultColumns, deprecatedCols);

    classList.push(...cols);

    return classList;
  }

  getCols(columns: any, defaultColumns?: DefaultCols, deprecatedCols?: DeprecatedCols) {
    const cols: string[] = [];

    if (columns) {
      columns.forEach((col: any) => {
        if (col.value) {
          cols.push(col.machineName + col.value);
        }
      });
    }

    if (cols.length <= 0 && deprecatedCols) {
      return this.getDeprecatedCols(deprecatedCols);
    }

    if (cols.length <= 0 && defaultColumns) {
      return Object.keys(defaultColumns).map(key => {
        const defaultColumnsAny: any = defaultColumns
        return key + defaultColumnsAny[key];
      });
    }

    return cols;
  }

  getDeprecatedCols(col: any) {
    if (!col) {
      return;
    }
    const cols = [];
    if (col.xlarge) {
      cols.push('col-xl-' + col.xlarge);
    }
    if (col.large) {
      cols.push('col-lg-' + col.large);
    }
    if (col.medium) {
      cols.push('col-md-' + col.medium);
    }
    if (col.small) {
      cols.push('col-sm-' + col.small);
    }
    if (col.xsmall) {
      cols.push('col-' + col.xsmall);
    }
    return cols;
  }

}
